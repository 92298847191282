.rm-logo {
    height: 100px;
    cursor: pointer;
}

.rm-layout {
    min-height: 100vh;
}

.rm-layout-content {
    margin: 0 16px;
}

.rm-layout-content > .child {
    padding: 24px;
    background: #fff;
    min-height: 90vh;
}

.rm-container {
    min-height: 60vh;
}

.rm-full-container {
    min-height: 70vh;
}

.rm-issue-banner {
    background: #E76441;
    color: #001529;
    font-weight: bold;
    font-size: 2em;
    cursor: pointer;
}

.rm-loader-login {
    min-width: 100%;
    min-height: 36vh;
}

.rm-loader-management {
    min-width: 100%;
    min-height: 60vh;
    padding-top: 90px;
}

.rm-loader-time-report {
    min-width: 100%;
    min-height: 60vh;
    padding-top: 90px;
}

.rm-message-info-login {
    min-width: 100%;
    min-height: 36vh;
}

.rm-message-info-time-report {
    min-width: 100%;
    min-height: 50vh;
}

.rm-message-info {
    min-width: 100%;
    min-height: 65vh;
}

.rm-dropdown-container {
    padding: 0;
    width: 188px;
    margin-bottom: 8px;
    display: 'block';
}

.rm-dropdown-filter {
    width: 188px;
    margin-bottom: 8px;
    display: 'block';
}

.rm-dropdown-button {
    width: 90px;
}

.rm-dropdown-button-reset {
    margin-left: 8px;
}

.rm-filtered-text {
    color: #1890ff!important;
}

.rm-grouping-by {
    padding-bottom: 20px;
    padding-top: 20px;
}

.rm-result-spacing {
    margin-bottom: 10px;
}

.rm-checkin-clock {
    font-size: 3em;
}

.ant-table-fixed {
    table-layout: fixed;
  }

  .ant-table-tbody > tr > td {
    word-wrap: break-word;
    word-break: break-all;
  }